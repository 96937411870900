// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.abe66::part(typography){color:var(--color-font-grey2);font-size:14px}.nnj2o::part(typography){color:var(--color-font-grey);font-size:12px;margin-top:-4px}`, "",{"version":3,"sources":["webpack://./src/components/users/user-item/UserItem.module.scss"],"names":[],"mappings":"AACE,yBACE,6BAAA,CACA,cAAA,CAKF,yBACE,4BAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".name {\n  &::part(typography) {\n    color: var(--color-font-grey2);\n    font-size: 14px;\n  }\n}\n\n.email {\n  &::part(typography) {\n    color: var(--color-font-grey);\n    font-size: 12px;\n    margin-top: -4px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"name": `abe66`,
	"email": `nnj2o`
};
export default ___CSS_LOADER_EXPORT___;
