export enum MessageType {
  QUESTION = 'question',
  ANSWER = 'answer',
}

export interface NewCommentParams {
  rfiQuestionId: string
  commentText: string
  isQuestionComment: boolean
}
