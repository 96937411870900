import {
  WppIconDownload,
  WppIconFavourites,
  WppIconFavouritesFilled,
  WppTypography,
} from '@wppopen/components-library-react'

import { useSetFavoriteUseCase } from 'api/mutations/use-cases/useSetFavoriteUseCase'
import { queryClient } from 'app/Root'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { UseCase } from 'types/use-cases/useCase'

export default function FileCmp(props: { useCase: UseCase; onClick: (useCase: UseCase) => void }) {
  const toast = useToast()
  const handleFavorite = useSetFavoriteUseCase({
    onSuccess: () => {
      toast.showToast({
        type: 'success',
        message: 'Favorite updated',
      })
    },
  })
  return (
    <div
      onClick={() => props.onClick(props.useCase)}
      className="rounded-8 w-[212px] h-[89px] px-3 py-2 bg-[#FFFFFF] flex flex-row gap-3 hover:cursor-pointer hover:bg-[#e9eaeb] hover:shadow transition-all duration-200 ease-in-out"
    >
      <div className="flex-1 flex flex-col gap-1">
        <WppTypography type="s-strong">
          {props.useCase.title.length > 23 ? `${props.useCase.title.slice(0, 23)}...` : props.useCase.title}
        </WppTypography>
        <WppTypography className="w-36 truncate" type="s-body">
          {props.useCase.pitchType}
        </WppTypography>
      </div>
      <div className="flex flex-col justify-between">
        {props.useCase.isFavorite ? (
          <WppIconFavouritesFilled
            className="hover:cursor-pointer"
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              handleFavorite.mutateAsync({ useCaseId: props.useCase.id, isFavorite: false }).then(() => {
                queryClient.invalidateQueries({
                  queryKey: [ApiQueryKeys.USE_CASES],
                })
              })
            }}
          />
        ) : (
          <WppIconFavourites
            className="hover:cursor-pointer"
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              handleFavorite.mutateAsync({ useCaseId: props.useCase.id, isFavorite: true }).then(() => {
                queryClient.invalidateQueries({
                  queryKey: [ApiQueryKeys.USE_CASES],
                })
              })
            }}
          />
        )}
        {props.useCase.fileUrl && (
          <WppIconDownload
            className="hover:cursor-pointer"
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              const link = document.createElement('a')
              link.href = props.useCase.fileUrl
              link.target = '_blank' // Open in a new tab

              link.download = props.useCase.fileUrl?.split('/').pop() ?? ''

              document.body.appendChild(link)

              link.click()

              document.body.removeChild(link)
            }}
          />
        )}
      </div>
    </div>
  )
}
