import { rfiHelperApi } from 'api'
import { RfiComment } from 'types/rfis/rfi'

export const patchComment = ({
  commentId,
  rfiQuestionId,
  commentText,
  isQuestionComment,
}: {
  commentId: string
  rfiQuestionId: string
  commentText: string
  isQuestionComment: boolean
}) =>
  rfiHelperApi.put<RfiComment>(`/comments/${commentId}`, {
    rfiQuestionId,
    commentText,
    isQuestionComment,
  })
