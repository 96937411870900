// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ae80W{position:sticky;bottom:0;z-index:1;width:calc(100% - 15px);background-color:#fff;border-end-end-radius:8px}.Ae80W::part(item){width:100%}._8tOl{margin-right:15px;border-radius:8px}._8tOl::part(card){background-color:#fff}`, "",{"version":3,"sources":["webpack://./src/pages/agency-edit-detail/AgencyEditKnowledgeBase.module.scss"],"names":[],"mappings":"AAAA,OACE,eAAA,CACA,QAAA,CACA,SAAA,CACA,uBAAA,CACA,qBAAA,CACA,yBAAA,CAEA,mBACE,UAAA,CAIJ,OACE,iBAAA,CACA,iBAAA,CAEA,mBACE,qBAAA","sourcesContent":[".newCategoryListItem {\n  position: sticky;\n  bottom: 0;\n  z-index: 1;\n  width: calc(100% - 15px);\n  background-color: white;\n  border-end-end-radius: 8px;\n\n  &::part(item) {\n    width: 100%;\n  }\n}\n\n.sidebarCard {\n  margin-right: 15px;\n  border-radius: 8px;\n\n  &::part(card) {\n    background-color: #FFFFFF;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newCategoryListItem": `Ae80W`,
	"sidebarCard": `_8tOl`
};
export default ___CSS_LOADER_EXPORT___;
