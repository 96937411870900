import { userDataApi } from 'api'
import { IUser } from 'types/users/userList'

export const userSearch = ({ username }: { username: string }) =>
  userDataApi.get<{ data: IUser[] }>('/users', {
    params: {
      offset: 0,
      limit: 50,
      filter: {
        search: username,
        active: true,
      },
    },
    paramsSerializer: {
      indexes: null,
    },
  })
