import { WppTypography } from '@wppopen/components-library-react'
import * as R from 'ramda'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useRfi } from 'api/queries/rfis/useRfi'
import { useRfiQuestions } from 'api/queries/rfis/useRfiQuestions'
import { RichTextPage } from 'components/textEditor/TextEditor'
import { RfiQuestion } from 'types/rfis/rfi'

import styles from './AiDraftResponse.module.scss'

interface ConvertedQuestion {
  id: string
  name: string
  questions: RfiQuestion[]
}

const convertQuestionsToCategories = R.groupBy<RfiQuestion>(R.prop('rfiCategoryId'))

export default function AiDraftResponse() {
  const params = useParams()
  const [convertedQuestions, setConvertedQuestions] = useState([] as ConvertedQuestion[])

  const { data: rfi } = useRfi({
    params: { rfiId: params.projectId || '' },
  })

  const { data: questions = [] } = useRfiQuestions({
    params: { rfiId: params.projectId || undefined },
  })

  useEffect(() => {
    if (questions.length === 0) {
      return
    }
    const groupedQuestions = convertQuestionsToCategories(questions)
    const result = R.map(categoryId => {
      const categoryQuestions = groupedQuestions[categoryId]
      const { rfiCategoryName } = categoryQuestions![0]
      return {
        id: categoryId.toString(),
        name: rfiCategoryName,
        questions: R.sortBy(R.prop('position'))(
          categoryQuestions?.map(q => ({
            ...q,
            id: q.id.toString(),
          })) || [],
        ),
      } as ConvertedQuestion
      /* filter out grousp with no category id - ie child comments and questions and clarifications */
    }, R.keys(groupedQuestions)).filter(group => group.id.toString() !== 'null')
    setConvertedQuestions([...result])
  }, [questions])

  return (
    <div className={styles.pageContentRight}>
      <WppTypography type="xl-heading">Ai-Generated Draft Response Preview</WppTypography>

      <RichTextPage questions={convertedQuestions} rfiData={rfi} />
    </div>
  )
}
