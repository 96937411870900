import { DatePickerClearEventDetail, DatePickerEventDetail, SelectChangeEventDetail } from '@wppopen/components-library'
import { WppDatepickerCustomEvent, WppSelectCustomEvent } from '@wppopen/components-library/dist/types/components'
import { WppDatepicker, WppListItem, WppModal, WppSelect, WppSpinner } from '@wppopen/components-library-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { NavigateFunction, useSearchParams } from 'react-router-dom'

import { useGetAgenciesLazy } from 'api/queries/agencies/useGetAgencies'
import { useGetAgencyCreators } from 'api/queries/agencies/useGetAgencyCreators'
import { useToast } from 'hooks/useToast'
import { AgencyFilterGroup } from 'pages/agency-dashboard/AgencyFilterGroup/AgencyFilterGroup'
import { Agency, AgencyFetchDataParams } from 'types/agencies/agencies'

import { AgencyAgGrid } from './AgencyAgGrid'
import { AgencyCards } from './AgencyCards'
import style from './agencyDashboard.module.scss'
import { AgencyDeleteModal } from './AgencyDeleteModal'
import { sortType } from './AgencyFilterGroup/types'
import { useAgencySortFilters } from './useAgencySortFilters'

interface PreparedFilters {
  range: Date[] | []
  createdBy: string
}

function isValidDate(date: unknown): date is Date {
  return date instanceof Date && !isNaN(date as unknown as number)
}

export const AgencyDashboardView = ({
  navigate,
  agencies,
  isFetching,
}: {
  isFetching: boolean
  navigate: NavigateFunction
  agencies: Agency[]
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [toggleView, setToggleView] = useState<'grid' | 'list'>((searchParams.get('view') as 'grid' | 'list') || 'grid')
  /* what is passed to the children */
  const [displayData, setDisplayData] = useState<Agency[]>([])
  /* prepared filters for when clicking apply */
  const [preparedFilters, setPreparedFilters] = useState<PreparedFilters>({
    range: [],
    createdBy: '',
  })
  const [activeFilterCount, setActiveFilterCount] = useState<number>(0)
  /* get / set the date picker range */
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [userNameValue, setUserNameValue] = useState<string>('')
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [selectedAgency, setSelectedAgency] = useState<{ id: string; name: string }>({ id: '', name: '' })
  const AllUsers = 'All users'
  const toast = useToast()
  const [, trigger] = useGetAgenciesLazy()
  const { data: agencyCreators } = useGetAgencyCreators()
  const [{ sortAsc, activeSortType }, handleSortFilters] = useAgencySortFilters('name')

  const handleToggle = (value: 'grid' | 'list') => {
    setSearchParams({ view: value })
    setToggleView(value)
  }

  /* store all the userNames for the filters */
  const userNameOptions = useMemo(
    () => [AllUsers].concat([...new Set(agencyCreators.map(item => item?.name))].sort((a, b) => a.localeCompare(b))),
    [agencyCreators],
  )

  const handleSortDisplayData = useCallback(
    (type: sortType) => {
      const sortedDisplayData = handleSortFilters(displayData, type)
      setDisplayData(sortedDisplayData)
    },
    [handleSortFilters, displayData],
  )

  /* util to filter by date */
  const handleSearchFilter = useCallback(() => {
    let returnValue = displayData
    return returnValue.filter(agency => searchIncludesAgency(searchTerm, agency))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayData, searchTerm])

  // util to filter by search term
  const searchIncludesAgency = (value: string, agency: Agency) =>
    agency.name.toLowerCase().includes(value.toLowerCase())

  const handleSearchChange = (value: string) => setSearchTerm(value)

  const handleFetchByFilters = async () => {
    const { createdBy, range } = preparedFilters
    let params: AgencyFetchDataParams = {}

    if (createdBy && createdBy !== AllUsers) {
      params.createdBy = createdBy
    }
    if (range.length) {
      const from = new Date(range?.[0]?.setHours(0, 0, 1, 0))
      const to = new Date(range?.[1]?.setHours(23, 59, 0, 0))
      if (isValidDate(from) && isValidDate(to)) {
        params.dateCreatedFrom = from.toISOString()
        params.dateCreatedTo = to.toISOString()
      }
    }
    try {
      trigger(params)
    } catch (e) {
      toast.showToast({
        message: `Error filtering agencies: ${e}`,
        type: 'error',
      })
    }
  }

  const handleFilterAddedBy = (e: WppSelectCustomEvent<SelectChangeEventDetail>) => {
    const value = e?.detail?.value || ''
    const userNameValue = value
    const createdByEmail = value === AllUsers ? '' : agencyCreators.find(item => item.name === value)?.email || ''

    /* if select all users reset */
    setPreparedFilters({ ...preparedFilters, createdBy: createdByEmail })
    setUserNameValue(userNameValue)
  }

  const handleFilterClearRange = (_event: WppDatepickerCustomEvent<DatePickerClearEventDetail>) =>
    setPreparedFilters({ ...preparedFilters, range: [] })

  const handleFilterCreatedRange = (event: WppDatepickerCustomEvent<DatePickerEventDetail>) => {
    const range = (event?.detail?.date as Date[]) || []
    setPreparedFilters({ ...preparedFilters, range })
  }

  const handleApplyFilters = () => {
    /* update the displayed agencies */
    setActiveFilterCount(Object.values(preparedFilters).reduce((accu, item) => accu + Number(item.length > 0), 0))
    /* filter the data */
    handleFetchByFilters()
  }

  /* reset everything and default to initial agencies set */
  const handleResetFilters = () => {
    setActiveFilterCount(0)
    setDisplayData(agencies)
    setUserNameValue('')
    setPreparedFilters({ range: [], createdBy: '' })
  }

  const handleEditAgencyClick = (agencyId: string) =>
    navigate(`/rfi-helper-tool/agency-dashboard/${agencyId}`, { state: { toggleView } })
  const handleDeleteAgencyClick = (agencyId: string, name: string) => {
    setSelectedAgency({ id: agencyId, name })
    setIsEditModalOpen(true)
  }

  useEffect(() => {
    // const sorted = activeFilterCount > 0 ? handleSortName(agencies) : agencies
    setDisplayData(agencies)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencies])

  useEffect(() => {
    if (!searchParams.get('view')) {
      setSearchParams({ view: 'grid' })
    }
  }, [searchParams, setSearchParams])

  return (
    <>
      <AgencyFilterGroup
        hideSorts={toggleView === 'list'}
        handleSearchChange={handleSearchChange}
        navigate={navigate}
        handleToggle={handleToggle}
        toggleView={toggleView}
        filterCount={activeFilterCount}
        handleResetFilters={handleResetFilters}
        handleApplyFilters={handleApplyFilters}
        handleSortFilter={handleSortDisplayData}
        disabled={isFetching}
        isAsc={sortAsc}
        activeSortType={activeSortType}
        filters={
          <>
            <WppSelect
              aria-label="Select Users"
              title="Added By"
              placeholder="Select Users"
              labelConfig={{
                text: 'Added By',
              }}
              onWppChange={handleFilterAddedBy}
              value={userNameValue}
            >
              {userNameOptions.map((item: string) => (
                <WppListItem key={item} value={item}>
                  <p slot="label">{item}</p>
                </WppListItem>
              ))}
            </WppSelect>

            <WppDatepicker
              labelConfig={{
                text: 'Date created',
              }}
              range
              onWppDateClear={handleFilterClearRange}
              onWppChange={handleFilterCreatedRange}
              className={style.datePicker}
            />
          </>
        }
      />
      {isFetching && (
        <div className="flex flex-row items-start justify-center h-52">
          <WppSpinner size="l" />
        </div>
      )}
      {toggleView === 'grid' && !isFetching && (
        <AgencyCards
          agencies={handleSearchFilter()}
          handleEditAgencyClick={handleEditAgencyClick}
          handleDeleteAgencyClick={handleDeleteAgencyClick}
        />
      )}
      {toggleView === 'list' && !isFetching && (
        <AgencyAgGrid
          agencies={handleSearchFilter()}
          handleEditAgencyClick={handleEditAgencyClick}
          handleDeleteAgencyClick={handleDeleteAgencyClick}
        />
      )}
      <WppModal open={isEditModalOpen} onWppModalClose={() => setIsEditModalOpen(false)} size="s">
        <AgencyDeleteModal
          agencyId={selectedAgency?.id || ''}
          name={selectedAgency?.name || ''}
          handleModalClose={() => {
            setIsEditModalOpen(false)
          }}
        />
      </WppModal>
    </>
  )
}
