// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JTOHH{color:var(--color-font-grey)}.oE_3_::part(content){width:150px}.oE_3_::part(actions-wrapper){padding:.5rem}.sYsyV,.sYsyV::part(datepicker-container){width:100%}.RnAny{width:32%;justify-content:space-between;padding:16px 12px 16px 16px;gap:8px;background-color:#fff}.e6Eqk::part(typography){font-family:Inter,sans-serif;font-size:18px;font-weight:600;line-height:28px;color:#121619;gap:8px}.rbchC::part(typography){font-family:Inter,sans-serif;font-size:14px;font-weight:400;line-height:22px;color:#4d5358}`, "",{"version":3,"sources":["webpack://./src/pages/agency-dashboard/agencyDashboard.module.scss"],"names":[],"mappings":"AAAA,OACE,4BAAA,CAGF,sBACE,WAAA,CAGF,8BACE,aAAA,CAGF,0CACE,UAAA,CAGF,OACE,SAAA,CACA,6BAAA,CACA,2BAAA,CACA,OAAA,CACA,qBAAA,CAIA,yBACE,4BAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,aAAA,CACA,OAAA,CAKF,yBACE,4BAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,aAAA","sourcesContent":[".greyColor {\n  color: var(--color-font-grey);\n}\n\n.editDeleteModal::part(content) {\n  width: 150px\n}\n\n.editDeleteModal::part(actions-wrapper) {\n  padding: .5rem;\n}\n\n.datePicker, .datePicker::part(datepicker-container) {\n  width: 100%;\n}\n\n.agencyCardContainer {\n  width: 32%;\n  justify-content: space-between;\n  padding: 16px 12px 16px 16px;\n  gap: 8px;\n  background-color: #FFFFFF;\n}\n\n.agencyCardTitle {\n  &::part(typography) {\n    font-family: Inter, sans-serif;\n    font-size: 18px;\n    font-weight: 600;\n    line-height: 28px;\n    color: #121619;\n    gap: 8px;\n  }\n}\n\n.agencyCardText {\n  &::part(typography) {\n    font-family: Inter, sans-serif;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 22px;\n    color: #4D5358;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"greyColor": `JTOHH`,
	"editDeleteModal": `oE_3_`,
	"datePicker": `sYsyV`,
	"agencyCardContainer": `RnAny`,
	"agencyCardTitle": `e6Eqk`,
	"agencyCardText": `rbchC`
};
export default ___CSS_LOADER_EXPORT___;
