import { useMarkets } from 'api/queries/markets/useMarkets'
import { RfiAgency } from 'types/rfis/rfi'

export const agenciesAsString = (agencies: RfiAgency[] | undefined) =>
  (agencies &&
    agencies?.reduce((accum, agency, index, arr) => {
      const isLastOrFirstOfOnly1 = index === arr.length - 1 || (index === 0 && arr.length === 1)
      accum += `${agency?.name}${!isLastOrFirstOfOnly1 ? ', ' : ''}` || ''
      return accum
    }, '')) ||
  ''

export const useGetMarketsByIds = (marketIds?: string[]) => {
  const { data: allMarkets } = useMarkets()

  return (
    marketIds &&
    allMarkets &&
    (marketIds?.reduce((accum, id, index, arr) => {
      const isLastOrFirstOfOnly1 = index === arr.length - 1 || (index === 0 && arr.length === 1)
      const market = allMarkets?.find(m => m.id === id)?.name
      if (market) {
        accum += `${market}${!isLastOrFirstOfOnly1 ? ', ' : ''}`
      }
      return accum
    }, '') ||
      '')
  )
}
