import {
  WppButton,
  WppIconAdd,
  WppIconDone,
  WppIconEdit,
  WppInput,
  WppListItem,
  WppModal,
  WppTypography,
} from '@wppopen/components-library-react'
import clsx from 'clsx'
import { prop, groupBy } from 'ramda'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useAddRfiQuestion } from 'api/mutations/rfis/useAddRfiQuestion'
import { useSetQuestionPosition } from 'api/mutations/rfis/useSetQuestionPosition'
import { useRfiQuestions } from 'api/queries/rfis/useRfiQuestions'
import { useTasksStatus } from 'api/queries/task-status/useTasksStatus'
import { queryClient } from 'app/Root'
import ChatCmp from 'components/chat/ChatCmp'
import { MultipleContainers } from 'components/draggable-list/DraggableList'
import { ProgressApiRes } from 'components/LoaderProgressWithDescription'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { RfiQuestionContext } from 'contexts/RfiQuestion'
import { RfiActionType, RfiQuestionAction } from 'contexts/types/rfiQuestion'
import { useToast } from 'hooks/useToast'
import { RfiQuestion } from 'types/rfis/rfi'

import styles from './AnswerQuestionsPage.module.scss'

interface ConvertedQuestion {
  id: string
  name: string
  questions: RfiQuestion[]
  parentId?: string | null
}

interface ModalState {
  open: boolean
  content: RfiActionType
}

const convertQuestionsToCategories = groupBy<RfiQuestion>(prop('rfiCategoryId'))

export default function AnswerQuestionsPage() {
  const params = useParams()
  const [task, setTask] = useState<ProgressApiRes | null>(null)
  const { showToast } = useToast()
  const { data: taskStatus } = useTasksStatus({
    params: { taskId: task?.id || '' },
    enabled: !!task?.id,
    refetchInterval: 2000,
  })

  const {
    data: questions = [],
    isFetching,
    isLoading,
  } = useRfiQuestions({
    params: { rfiId: params.projectId || undefined },
  })
  const [convertedQuestions, setConvertedQuestions] = useState([] as ConvertedQuestion[])
  const [inDragMode, setInDragMode] = useState(false)
  const [newQuestionText, setNewQuestionText] = useState('')
  const [selectedRfiQuestionId, setSelectedRfiQuestionId] = useState<string | null>(null)
  const [rfiQuestionAction, setRfiQuestionAction] = useState<RfiQuestionAction | null>(null)
  const [modalState, setModalState] = useState<ModalState>({
    open: false,
    content: null,
  })
  const {
    mutateAsync: addRfiQuestion,
    isPending,
    // isIdle,
  } = useAddRfiQuestion({
    onError: error => {
      showToast({
        message: error.message,
        type: 'error',
      })

      toggleModal()
      setNewQuestionText('')
    },
  })
  const { mutateAsync: setQuestionPosition, isPending: setPositionPending } = useSetQuestionPosition()
  /*
   *   questionValue overrides state
   *   because the event is passed back up from the child component
   */
  const addNewQuestion = async (questionValue = '') => {
    if (newQuestionText.length === 0 && !questionValue) {
      return
    }

    /*
     *  we now might have a 'null' category id
     *  becuase the API now returns rfiQuestions, with related children array of rfiQuestionIds
     *  we need to find the last question that has a non-null id where the children have been put in a null cateogry
     *  to avoid them displaying as top-level questions in the list structure
     */
    const lastQuestionNotNull = convertedQuestions.findLast(question => question?.id.toString() !== 'null')

    await addRfiQuestion({
      questionText: newQuestionText || questionValue || '',
      rfiCategoryId: lastQuestionNotNull?.id || '',
      rfiId: params.projectId || '',
    })
      .then(data => {
        setTask(data?.data)
        setNewQuestionText('')
        !questionValue && toggleModal()
        showToast({
          message: 'Adding a new question. Please wait...',
          type: 'success',
        })
      })
      .catch(() => {
        showToast({
          message: 'Failed to add new question',
          type: 'error',
        })
        // setNewQuestionText('')
        // toggleModal()
      })
  }

  const handleSavePositions = async (
    overContainer: string,
    prevId: string | null,
    nextId: string | null,
    activeId: string,
  ) => {
    setQuestionPosition({
      prevId,
      nextId,
      activeId,
      rfiCategoryId: overContainer,
    })
  }

  const toggleDragMode = () => setInDragMode(prev => !prev)

  const toggleModal = (content?: ModalState['content']) => {
    setModalState(prev => ({
      open: !prev.open,
      content: content ?? prev.content,
    }))
  }

  useEffect(() => {
    if (questions.length === 0 || isFetching || isLoading) {
      return
    }
    const groupedQuestions = convertQuestionsToCategories(questions)

    const result = Object.keys(groupedQuestions)
      .map(categoryId => {
        const categoryQuestions = groupedQuestions[categoryId]
        const { rfiCategoryName } = categoryQuestions![0]

        return {
          id: categoryId.toString(),
          name: rfiCategoryName,
          questions:
            categoryQuestions
              ?.map(q => ({
                ...q,
                id: q.id.toString(),
              }))
              // filter out any child questions
              .filter(q => !q?.parentId) || [],
        } as ConvertedQuestion
      }) // filter out any empty categories
      .filter(c => c?.id !== null)

    setConvertedQuestions(result)
  }, [questions, isFetching, isLoading])

  useEffect(() => {
    const { status } = taskStatus || {}
    if (status === 'completed') {
      setTask(null)
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.RFI_QUESTIONS] }).then(() => {
        showToast({
          message: 'New question added successfully',
          type: 'success',
        })
      })
    }
  }, [taskStatus, showToast])

  if (isLoading || isFetching) {
    return <div>loading...</div>
  }
  return (
    <RfiQuestionContext.Provider
      value={{
        rfiQuestionId: selectedRfiQuestionId,
        setRfiQuestionId: setSelectedRfiQuestionId,
        inDragMode,
        rfiQuestionAction,
        setRfiQuestionAction: action => {
          setRfiQuestionAction(action)
          toggleModal(action.type)
        },
        setInDragMode,
      }}
    >
      <div className={clsx('h-full')}>
        <div className="flex items-center justify-between">
          <WppTypography type="xl-heading">Answer Specific Questions in RFI</WppTypography>
          {convertedQuestions.length > 0 && (
            <button onClick={toggleDragMode}>{inDragMode ? <WppIconDone /> : <WppIconEdit />}</button>
          )}
        </div>
        <div className="flex flex-row mt-3 items-stretch gap-3 bg-[#FFFFFF] rounded-lg">
          <div className="flex-1 py-[15px] pl-[15px]">
            <div className={clsx(styles.pageContentRight)}>
              {convertedQuestions.length > 0 && (
                <MultipleContainers
                  disabled={setPositionPending || isPending}
                  questionsFromBe={questions}
                  items={convertedQuestions}
                  setItems={setConvertedQuestions}
                  savePositions={handleSavePositions}
                />
              )}
            </div>
            <div className="mt-10">
              <WppListItem onClick={() => toggleModal('ASK_QUESTION')}>
                <WppIconAdd slot="right" />
                <p slot="label">Add New Question</p>
              </WppListItem>
            </div>
          </div>
          <div className={clsx(styles.chatContainer, 'flex-1')}>
            <ChatCmp addNewQuestion={addNewQuestion} />
          </div>
        </div>
        <WppModal open={modalState.open} className={styles.modalContainer}>
          {modalState.content === 'ASK_QUESTION' && (
            <>
              <h3 slot="header">Add New Question</h3>
              <p slot="body">
                <WppInput
                  labelConfig={{ text: 'Enter a new question' }}
                  onWppChange={e => setNewQuestionText(e.detail.value ?? '')}
                  value={newQuestionText}
                  required
                />
              </p>
              <div className="flex flex-row items-center justify-end gap-2" slot="actions">
                <WppButton loading={isPending} variant="primary" size="s" onClick={() => addNewQuestion('')}>
                  Add
                </WppButton>
                <WppButton
                  disabled={isPending}
                  variant="secondary"
                  size="s"
                  onClick={() => {
                    toggleModal()
                    setNewQuestionText('')
                  }}
                >
                  Close
                </WppButton>
              </div>
            </>
          )}
        </WppModal>
      </div>
    </RfiQuestionContext.Provider>
  )
}
