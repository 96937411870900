import { WppAvatar, WppTypography } from '@wppopen/components-library-react'

import styles from './UserItem.module.scss'

interface IUserItem {
  avatarUrl: string
  firstName: string
  lastName: string
  email: string
}

export const UserItem = ({ avatarUrl, firstName, lastName, email }: IUserItem) => {
  const name = `${firstName} ${lastName}`
  return (
    <div className="w-full flex gap-3 items-center">
      <WppAvatar name={name} src={avatarUrl} className="w-8 h-8 rounded-full object-contain" />
      <div className="flex flex-col gap-1 justify-center">
        <WppTypography className={styles.name}>{`${firstName} ${lastName}`}</WppTypography>
        <WppTypography className={styles.email} type="s-body">
          {email}
        </WppTypography>
      </div>
    </div>
  )
}
