export enum ApiQueryKeys {
  AGENCIES = 'agencies',
  AGENCY_NAMES = 'agency_names',
  AGENCY_CATEGORIES = 'agency_categories',
  USER_SETTINGS = 'user_settings',
  RFIS = 'rfis',
  RFI = 'rfi',
  MARKETS = 'markets',
  RFI_QUESTIONS = 'rfi_questions',
  RFI_QUESTION = 'rfi_question',
  PITCH_TYPES = 'pitch_types',
  SUMMARIES = 'summaries',
  USE_CASES = 'use_cases',
  TASKS_STATUS = 'tasks_status',
  REGIONS = 'regions',
  USER_SEARCH = 'user_search',
  RFI_MEMBERS = 'rfi_members',
}
