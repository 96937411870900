// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OJtq2::part(typography){font-size:14px;color:var(--color-font-grey);margin-top:.25rem}.otnzj::part(pill-wrapper){padding:0 10px 0 4px}`, "",{"version":3,"sources":["webpack://./src/components/users/UsersList.module.scss"],"names":[],"mappings":"AACE,yBACI,cAAA,CACA,4BAAA,CACA,iBAAA,CAKJ,2BACI,oBAAA","sourcesContent":[".subText {\n  &::part(typography) {\n      font-size: 14px;\n      color: var(--color-font-grey);\n      margin-top: 0.25rem;\n  }\n}\n\n.pill {\n  &::part(pill-wrapper) {\n      padding: 0 10px 0 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subText": `OJtq2`,
	"pill": `otnzj`
};
export default ___CSS_LOADER_EXPORT___;
