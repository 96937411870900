import { Outlet, useParams, Navigate } from 'react-router-dom'

import useProjectContext from 'hooks/useProjectContext'

export const ProjectRoutes = () => {
  const { projectId } = useParams()
  const { state = null } = useProjectContext()

  const isFetching = state?.isFetchingProjects || state?.isLoadingProjects

  let hasPermission = Boolean(state?.projects?.find(rfi => rfi.id === projectId)) || false
  if (isFetching) {
    return null
  }
  return hasPermission ? <Outlet /> : <Navigate to="/rfi-helper-tool/no-access" />
}
