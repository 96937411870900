// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._xWNb{display:flex}.UfOcB{flex:1 1;flex-grow:1;padding:20px;background-color:#f8f9fb}`, "",{"version":3,"sources":["webpack://./src/pages/AppLayout.module.scss"],"names":[],"mappings":"AAAA,OACI,YAAA,CAGJ,OACI,QAAA,CACA,WAAA,CACA,YAAA,CACA,wBAAA","sourcesContent":[".appLayoutContainer{\n    display: flex;\n  }\n\n.appLayoutContent{\n    flex: 1;\n    flex-grow: 1;\n    padding: 20px;\n    background-color: #F8F9FB;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appLayoutContainer": `_xWNb`,
	"appLayoutContent": `UfOcB`
};
export default ___CSS_LOADER_EXPORT___;
