import { WppProgressIndicator, WppTypography } from '@wppopen/components-library-react'
import clsx from 'clsx'
import { memo, useEffect, useState } from 'react'

import styles from './LoaderProgressWithDescription.module.scss'

interface Props {
  taskStatus: ProgressApiRes | null
}

export interface ProgressApiRes {
  id: string
  completed: boolean
  error: boolean
  status: string
  totalSteps: number
  currentStep: number
  resultObjectId: string
  taskQuestionId?: string
}

export const LoaderProgressWithDescription = memo(({ taskStatus }: Props) => {
  const [percentage, setPercentage] = useState(0)

  useEffect(() => {
    if (taskStatus?.currentStep) {
      setPercentage(Math.floor((taskStatus.currentStep / taskStatus.totalSteps) * 100))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskStatus?.currentStep])

  return (
    <div className={clsx(styles.chatBubbleAnswer, 'flex flex-col items-start flex-nowrap gap-4')}>
      <WppProgressIndicator className={styles.progressBar} variant="bar" isShowPercentage value={percentage} />
      <div className={styles.text}>
        <WppTypography type="xs-body" className={styles.textInner}>
          {taskStatus?.status || 'Upload in progress...'}
        </WppTypography>
      </div>
    </div>
  )
})
