import { AttachmentMetadata } from '@wppopen/core'

export interface GetRfiReturn {
  id?: string
  member: string
  role: Role
}

export interface IUser {
  id: string
  firstname: string
  lastname: string
  avatarUrl: string | null
  email: string
  sourceType?: string
  active?: boolean
  agency?: string
  isExternal?: string
  country?: string | null
  countryAlpha2Code?: string | null
  department?: string | null
  avatarOriginal?: AttachmentMetadata | null
  avatarThumbnail?: AttachmentMetadata | null
  jobTitle?: string | null
  officeLocation?: string | null
  createdAt?: string
  updatedAt?: string
  mdProperties?: {
    mdType: string
    mdId: string
  }[]
  preferredTenantId?: string | null
  sourceId?: string
  role?: {
    id: string
    name: Role
  }
}

export enum Role {
  Owner = 'Owner',
  Contributor = 'Contributor',
  Commenter = 'Commenter',
  Viewer = 'Viewer',
}

export interface IUserRole {
  id: string
  name: Role
}
