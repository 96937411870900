import { EditorState, Modifier } from 'draft-js'

import { RfiQuestion } from 'types/rfis/rfi'

import { MessageType } from './types'

export const mergeQuestions = (rfiQuestion: RfiQuestion | null): RfiQuestion[] =>
  (rfiQuestion && [rfiQuestion, ...(rfiQuestion?.childQuestions || [])]) || []

export const updateMergedConversation = (questions: RfiQuestion[]) =>
  questions
    .map(question => {
      const questionMessage = {
        id: `${question.id}-${MessageType.QUESTION}`,
        type: MessageType.QUESTION,
        content: question.questionText,
        timestamp: new Date().toISOString(),
        rfiQuestionId: question.id,
        parentId: question.parentId,
        comments: question.questionComments,
      }
      const answerMessage = {
        id: `${question.id}-${MessageType.ANSWER}`,
        type: MessageType.ANSWER,
        content: question.proposedAnswer,
        timestamp: new Date().toISOString(),
        rfiQuestionId: question.id,
        parentId: question.parentId,
        comments: question.answerComments,
      }
      return [questionMessage, answerMessage]
    })
    .flat()

// https://github.com/jpuri/draftjs-utils/blob/master/js/block.js
export const getResetEditorState = (editorState: EditorState) => {
  const blocks = editorState.getCurrentContent().getBlockMap().toList()
  const updatedSelection = editorState.getSelection().merge({
    anchorKey: blocks.first().get('key'),
    anchorOffset: 0,
    focusKey: blocks.last().get('key'),
    focusOffset: blocks.last().getLength(),
  })
  const newContentState = Modifier.removeRange(editorState.getCurrentContent(), updatedSelection, 'forward')
  const newState = EditorState.push(editorState, newContentState, 'remove-range')
  return newState
}
