import { WppActionButton, WppEmptyNothingFound, WppTypography } from '@wppopen/components-library-react'
import { useNavigate } from 'react-router-dom'

import { navigation } from 'components/SidebarCmp'

export const NoAccess = () => {
  const navigate = useNavigate()
  return (
    <div className="flex flex-col gap-6 items-center justify-center w-full h-[60dvh]">
      <WppEmptyNothingFound />
      <div className="flex flex-col gap-2 items-center justify-center">
        <WppTypography type="m-strong">You don't have permission to access this page</WppTypography>
        <WppTypography type="m-body">You have to be added as a team member to see the project content</WppTypography>
      </div>
      <WppActionButton role="navigation" variant="primary" onClick={() => navigate(navigation.home)}>
        Go to home page
      </WppActionButton>
    </div>
  )
}
