import {
  WppIconTableSort,
  WppTypography,
  WppIconRemoveCircle,
  WppSelect,
  WppListItem,
  WppDivider,
} from '@wppopen/components-library-react'
import { useMemo, useState } from 'react'

import { userRoles } from 'constants/userRoles'
import { CreatedOrUpdatedBy } from 'types/rfis/rfi'

import { LoadMore } from './load-more/LoadMore'
import { UserItem } from './user-item'
import { Role, IUser, IUserRole } from '../../types/users/userList'

interface IUsersList {
  users: IUser[]
  onRoleChange: ({ userId, role }: { userId: string; role: IUserRole }) => void
  onRemoveUser: (user: IUser) => void
  disabled: boolean
  userDetails: IUser
  creator?: CreatedOrUpdatedBy
}

enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

type SortBy = 'Member' | 'Role'

interface SortOption {
  sortBy: SortBy
  order: SortOrder
}

const DEFAULT_USERS_COUNT = 7

export function UsersList({ users, onRoleChange, onRemoveUser, disabled, userDetails, creator }: IUsersList) {
  const currentUser: IUser = {
    id: userDetails.id,
    firstname: userDetails.firstname,
    lastname: userDetails.lastname,
    avatarUrl: userDetails.avatarUrl ?? '',
    role: {
      id: '1',
      name: Role.Owner,
    },
    email: userDetails.email,
  }
  const Creator = creator ? users.find(mem => mem.email === creator.email) || currentUser : currentUser
  const [sortOption, setSortOption] = useState<SortOption>({
    sortBy: 'Member',
    order: SortOrder.ASC,
  })
  const [shownUsersCount, setShownUsersCount] = useState(DEFAULT_USERS_COUNT)

  const sortedUsers = useMemo(() => {
    const { order, sortBy } = sortOption
    const slicedUsers = users.slice(0, shownUsersCount - 1).map(usr => ({
      ...usr,
      role: usr.role ?? { id: '2', name: Role.Contributor },
    }))

    if (sortBy === 'Member') {
      return order === SortOrder.ASC
        ? slicedUsers.sort((a, b) => a.firstname.localeCompare(b.firstname))
        : slicedUsers.sort((a, b) => b.firstname.localeCompare(a.firstname))
    }
    if (sortBy === 'Role') {
      return order === SortOrder.ASC
        ? slicedUsers.sort((a, b) => a.role.name.localeCompare(b.role.name))
        : slicedUsers.sort((a, b) => b.role.name.localeCompare(a.role.name))
    }
    return users
  }, [sortOption, users, shownUsersCount])

  const onSortList = (sortBy: SortBy) => {
    setSortOption(prevState => ({
      sortBy,
      order: prevState.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC,
    }))
  }

  const onLoadMore = () => {
    const diff = users.length - shownUsersCount
    if (diff > DEFAULT_USERS_COUNT) {
      setShownUsersCount(prevCount => prevCount + DEFAULT_USERS_COUNT)
      return
    }
    setShownUsersCount(prevCount => prevCount + diff)
  }

  const renderUserRow = (user: IUser) => (
    <>
      <div className="flex my-4">
        <div className=" w-1/2">
          <UserItem
            avatarUrl={user?.avatarUrl || ''}
            firstName={user.firstname}
            lastName={user.lastname}
            email={user.email}
          />
        </div>
        <div className="flex gap-9 items-center w-1/2">
          <div className="w-[90%]">
            <WppSelect
              required
              disabled={disabled || undefined}
              placeholder="Select Role"
              value={(user.role?.name || user?.role) ?? Role.Contributor}
              onWppChange={e => {
                onRoleChange({
                  userId: user.id,
                  role: userRoles.find(r => r.name === e.detail.value)!,
                })
              }}
            >
              {user.id === Creator.id ? (
                <WppListItem key={user.role?.id} value={user.role?.name}>
                  <p slot="label">{user.role?.name}</p>
                </WppListItem>
              ) : (
                userRoles.map(role => (
                  <WppListItem key={role.name} value={role.name}>
                    <p slot="label">{role.name}</p>
                  </WppListItem>
                ))
              )}
            </WppSelect>
          </div>
          {user.id !== Creator.id && (
            <button onClick={() => onRemoveUser(user)} className="cursor-pointer" disabled={disabled}>
              <WppIconRemoveCircle />
            </button>
          )}
        </div>
      </div>
      <WppDivider />
    </>
  )

  return (
    <div className="p-4">
      <div className="flex w-full mb-4 max-h-[600px] overflow-y-auto">
        <div className="w-1/2">
          <div className="flex items-center gap-1 cursor-pointer" onClick={() => onSortList('Member')}>
            <WppTypography type="m-strong">Member</WppTypography>
            <WppIconTableSort />
          </div>
        </div>
        <div className="w-1/2">
          <div className="flex items-center gap-1 cursor-pointer" onClick={() => onSortList('Role')}>
            <WppTypography type="m-strong">Role</WppTypography>
            <WppIconTableSort />
          </div>
        </div>
      </div>
      <WppDivider />

      {renderUserRow(Creator)}
      {sortedUsers.length > 0 &&
        sortedUsers
          .filter(usr => usr?.email !== Creator?.email)
          .map(user => <div key={user.id}>{renderUserRow(user)}</div>)}

      {users.length > DEFAULT_USERS_COUNT && (
        <div className="my-6 w-full">
          <LoadMore currentCount={shownUsersCount} total={users.length} onLoadMore={onLoadMore} />
        </div>
      )}
    </div>
  )
}
