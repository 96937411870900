import { WppIconCopy, WppTypography } from '@wppopen/components-library-react'
import Markdown from 'react-markdown'
import { useParams } from 'react-router-dom'

import { useRfi } from 'api/queries/rfis/useRfi'
import { useToast } from 'hooks/useToast'

export default function RfiSummaryPage() {
  const params = useParams()
  const { showToast } = useToast()
  const {
    data: rfi,
    error,
    isLoading,
  } = useRfi({
    params: { rfiId: params.projectId || '' },
  })
  const copyToClipboard = () => {
    navigator.clipboard.writeText(rfi?.rfiSummary ?? '')
    showToast({
      message: 'Summary copied to clipboard',
      type: 'success',
      duration: 4000,
    })
  }
  const hasNoSummary = !isLoading && !error && !rfi?.rfiSummary

  return (
    <div>
      <div className="flex justify-between">
        <WppTypography type="xl-heading">Summarise RFI / RFP</WppTypography>
        {rfi.rfiSummary && (
          <button onClick={copyToClipboard} type="button">
            <WppIconCopy />
          </button>
        )}
      </div>
      <div className="mt-3 p-6 bg-[#FFFFFF] rounded-lg">
        {(hasNoSummary || error) && (
          <div className="flex flex-row items-center justify-center">
            <WppTypography type="s-strong">
              No RFI / RFP summary available. Please summarise the RFI / RFP to help the team understand the project.
            </WppTypography>
          </div>
        )}
        {!error && rfi && (
          <WppTypography className="max-w-full whitespace-pre-wrap break-words" type="s-body">
            <Markdown>{rfi?.rfiSummary}</Markdown>
          </WppTypography>
        )}
      </div>
    </div>
  )
}
