import { PaginationChangeEventDetail } from '@wppopen/components-library'
import { WppPagination, WppSpinner } from '@wppopen/components-library-react'
import { AgGridReact } from 'ag-grid-react'
import { useState, useRef } from 'react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import '@wppopen/components-library/dist/collection/ag-theme-wpp.css'

import HeaderCell from 'components/Grid/HeaderCell'
import { AgTableProps } from 'pages/agency-dashboard/AgencyFilterGroup/types'
import { Agency } from 'types/agencies/agencies'

import { columnDefenitions } from './columnsDefenitions'

export type TableDataItem = Agency & { user: { name: string; img: string } }

export const AgGridTable = ({ agencies, handleEditAgencyClick, handleDeleteAgencyClick }: AgTableProps) => {
  const gridRef = useRef<AgGridReact<TableDataItem>>(null)

  const columnDefs = columnDefenitions(handleEditAgencyClick, handleDeleteAgencyClick)
  const [paginationPageSize, setPaginationPageSize] = useState<number>(10)
  const [currPage, setCurrPage] = useState<number>(1)

  const handlePaginationChange = (event: CustomEvent<PaginationChangeEventDetail>) => {
    const { itemsPerPage, page } = event.detail

    if (paginationPageSize !== itemsPerPage) {
      //@ts-ignore
      gridRef.current!.api.paginationSetPageSize(itemsPerPage)
      setPaginationPageSize(itemsPerPage)
    }

    if (currPage !== page) {
      gridRef.current!.api.paginationGoToPage(page - 1)
      setCurrPage(page)
    }
  }
  return (
    <div data-testid="ag-grid-table">
      <div className="ag-theme-wpp mb-[32] w-full" key="123">
        <AgGridReact
          ref={gridRef}
          gridId="agency-grid"
          rowData={agencies}
          columnDefs={columnDefs}
          defaultColDef={{
            headerComponent: HeaderCell,
            sortable: false,
          }}
          onRowClicked={({ data, event }) => {
            const target = event?.target as HTMLElement
            if (!target?.dataset?.contextMenu) {
              handleEditAgencyClick(data.id)
            }
          }}
          rowSelection="multiple"
          rowDragMultiRow
          animateRows
          rowDragManaged
          pagination
          paginationPageSize={paginationPageSize}
          suppressPaginationPanel
          loadingOverlayComponent={() => <WppSpinner size="m" />}
          rowClass="cursor-pointer"
          rowClassRules={{
            'with-error': data => (data.data ? !!data.data.meta?.error : false),
          }}
          domLayout="autoHeight"
        />
      </div>
      {agencies.length > paginationPageSize && (
        <WppPagination
          count={agencies.length}
          itemsPerPage={[3, 5, 10]}
          activePageNumber={currPage}
          pageSelectThreshold={9}
          data-testid="pagination-list"
          selectedItemPerPage={paginationPageSize}
          onWppChange={handlePaginationChange}
          className="mt-6"
        />
      )}
    </div>
  )
}
