import { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import AgencyDashboard from 'pages/agency-dashboard'
import { AgencyEditDetail } from 'pages/agency-edit-detail'
import AiDraftResponse from 'pages/ai-draft-response/AiDraftResponse'
import AnswerQuestionsPage from 'pages/answer-questions/AnswerQuestionsPage'
import AppLayout from 'pages/AppLayout'
import HomePage from 'pages/home/HomePage'
import NewProjectPage from 'pages/new-project/NewProjectPage'
// import ProposedResponseStructurePage from 'pages/proposed-response-structure/ProposedResponseStructurePage'
import { NoAccess } from 'pages/NoAccess'
import { ProjectDetailPage } from 'pages/project-detail'
import RelevantNotesPage from 'pages/relevant-notes/RelevantNotesPage'
import RfiSummaryPage from 'pages/rfi-summary/RfiSummaryPage'

import { AgencyRoutes } from './AgencyRoutes'
import { ProjectRoutes } from './ProjectRoutes'

// const HomePage = React.lazy(() => import('pages/home/HomePage'))
// const NewProjectPage = React.lazy(() => import('pages/new-project/NewProjectPage'))
// const ProposedResponseStructurePage = React.lazy(
//   () => import('pages/proposed-response-structure/ProposedResponseStructurePage'),
// )
// const RelevantNotesPage = React.lazy(() => import('pages/relevant-notes/RelevantNotesPage'))
// const RfiSummaryPage = React.lazy(() => import('pages/rfi-summary/RfiSummaryPage'))
// const AiDraftResponse = React.lazy(() => import('pages/ai-draft-response/AiDraftResponse'))
// const AnswerQuestionsPage = React.lazy(() => import('pages/answer-questions/AnswerQuestionsPage'))
// const AgencyDashboard = React.lazy(() => import('pages/agency-dashboard'))

export default function RoutesCmp() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route index element={<Navigate replace to="rfi-helper-tool/home" />} />
          <Route path="rfi-helper-tool/home" element={<HomePage />} />
          <Route path="rfi-helper-tool/new-project" element={<NewProjectPage />} />

          {/* Project Routes */}
          <Route element={<ProjectRoutes />}>
            <Route path="rfi-helper-tool/rfi-summary/:projectId" element={<RfiSummaryPage />} />
            <Route path="rfi-helper-tool/relevant-notes/:projectId" element={<RelevantNotesPage />} />
            <Route path="rfi-helper-tool/project-detail/:projectId" element={<ProjectDetailPage />} />
            <Route path="rfi-helper-tool/answer-questions/:projectId" element={<AnswerQuestionsPage />} />
            <Route path="rfi-helper-tool/ai-draft-response/:projectId" element={<AiDraftResponse />} />
          </Route>
          {/* end project routes */}

          {/* Agency Routes */}
          <Route element={<AgencyRoutes />}>
            <Route path="rfi-helper-tool/agency-dashboard" element={<AgencyDashboard />} />
            <Route path="rfi-helper-tool/agency-dashboard/:agencyId" element={<AgencyEditDetail />} />
          </Route>
          {/* end Agency Routes */}
          {/* error pages */}
          <Route path="/rfi-helper-tool/no-access" element={<NoAccess />} />
          <Route path="*" element={<Navigate replace to="/rfi-helper-tool/home" />} />
        </Route>
      </Routes>
    </Suspense>
  )
}
