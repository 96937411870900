import { WppSkeleton, WppTypography } from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import { lazy, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useUpdateRfi } from 'api/mutations/rfis/useUpdateRfi'
import { usePitchTypes } from 'api/queries/pitch-types/usePitchTypes'
import { queryClient } from 'app/Root'
import { UserCollaboration } from 'components/users/UserCollaboration'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import useProjectContext from 'hooks/useProjectContext'
import { useToast } from 'hooks/useToast'
import { ProjectDetails, UpdateRfiProps } from 'types/rfis/rfi'
import { GetRfiReturn, IUser, Role } from 'types/users/userList'
import { agenciesAsString, useGetMarketsByIds } from 'utils/projectUtils'

import { ProjectDetail } from './ProjectDetail'

interface ProjectDetailType {
  name: string
  value: string
}

const ProjectMembersGrid = lazy(() =>
  import('./ProjectMembersGrid').then(module => ({ default: module.ProjectMembersGrid })),
)

export const ProjectDetailPage = () => {
  const [projectDetails, setProjectDetails] = useState<ProjectDetailType[]>([])
  const { projectId } = useParams()
  const [currentProject, setCurrentProject] = useState<ProjectDetails | null>(null)
  const { state = null } = useProjectContext()
  const [initUsers, setInitUsers] = useState<IUser[]>([])
  const { mutateAsync: updateProject } = useUpdateRfi()

  const { data: pitchTypes, isFetching, isLoading } = usePitchTypes()

  const project = state?.projects?.find(project => project.id === projectId)

  const { showToast } = useToast()

  const {
    osContext: { userDetails },
  } = useOs()

  const creator = currentProject?.createdBy

  const currentUser = {
    id: userDetails.id,
    firstname: userDetails.firstname,
    lastname: userDetails.lastname,
    avatarUrl: userDetails.avatarUrl ?? '',
    role: Role.Owner,
    email: userDetails.email,
  }

  const isCreator = userDetails.email === currentProject?.createdBy?.email || false

  const pitchType =
    (project?.pitchTypeId && pitchTypes?.find(p => p.id === project?.pitchTypeId)?.typeDescription) || ''

  const markets = useGetMarketsByIds(project?.marketIds)
  const agencies = agenciesAsString(project?.agencies)
  useEffect(() => {
    setProjectDetails([
      { name: 'Client', value: project?.clientName || '' },
      { name: 'Pitch', value: pitchType || '' },
      { name: 'Markets', value: markets || '' },
      { name: 'Agency', value: agencies },
    ])
    setCurrentProject(state?.projects?.find(project => project.id === projectId) || null)
  }, [project, pitchType, markets, agencies, state?.projects, projectId])

  const fetching = isFetching || state?.isFetchingProjects || state?.isLoadingProjects || isLoading
  const members = project?.projectMembers

  /* methods */
  const handleUpdateProject = async (updatedUsers: GetRfiReturn[], prevUpdatedUsers: GetRfiReturn[]) => {
    if (!currentProject) return
    const currentMember = {
      member: currentUser.email,
      role: currentUser.role || Role.Owner,
    }
    const projectMembers = [currentMember, ...updatedUsers].map(user => ({
      member: user?.member || '',
      role: user?.role || Role.Contributor,
    }))

    const transformProjectPayload: UpdateRfiProps = {
      id: currentProject.id,
      projectName: currentProject.projectName,
      client: currentProject.clientId,
      pitchTypeId: currentProject.pitchTypeId,
      agencies:
        currentProject.agencies?.map(agency => ({
          id: agency.id,
        })) || [],
      activeStatus: currentProject.activeStatus,
      marketIds: currentProject.marketIds,
      projectMembers,
    }
    if (!project || JSON.stringify(updatedUsers) === JSON.stringify(prevUpdatedUsers)) return
    try {
      await updateProject(transformProjectPayload)
      const addOrRemovedUsers = updatedUsers.length - prevUpdatedUsers.length

      if (prevUpdatedUsers.length !== updatedUsers.length) {
        showToast({
          message: `${addOrRemovedUsers > 0 ? 'Added' : 'Removed'} ${Math.abs(addOrRemovedUsers)} user(s)`,
          type: 'success',
          duration: 1000,
        })
      }
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.RFIS] })
    } catch (error) {
      showToast({
        message: 'Error updating project',
        type: 'error',
      })
      console.log('Error updating project', error)
    }
  }

  useEffect(() => {
    if (creator && project) {
      const mappedProjectMembers =
        project?.projectMembers?.map(member => ({
          id: Math.random().toString(),
          firstname: member.memberDetail.name.split(' ')?.[0],
          lastname: member.memberDetail.name.split(' ')?.[1],
          avatarUrl: member.memberDetail.imgThumbnail || member.memberDetail.img || '',
          email: member.memberDetail.email,
          role: { id: Math.random().toString(), name: member.role as Role },
        })) || []
      if (mappedProjectMembers) {
        setInitUsers(mappedProjectMembers)
      }
    }
  }, [creator, project])

  return (
    <div data-testid="projectDetails">
      <WppTypography type="xl-heading">{project?.projectName || 'Project Details'}</WppTypography>
      <div className="mt-3 bg-[#FFFFFF] rounded-lg">
        <div className="flex gap-4 flex-wrap p-6 pb-2">
          {projectDetails.map(detail => (
            <ProjectDetail fetching={fetching || false} {...detail} key={detail.name} />
          ))}
        </div>
        {/* is owner */}
        {isCreator && !fetching && (
          <div className="flex gap-4 p-6 pb-2 flex-col pt-4 bg-[#FFFFFF]">
            {creator && (
              <UserCollaboration
                onUsersUpdated={handleUpdateProject}
                initUsers={initUsers}
                snug
                disabledInputsCondition={false}
                isTaskRunning={false}
                creator={creator}
              />
            )}
          </div>
        )}
        {/* Team Members */}
        {fetching && (
          <div className="flex gap-4 flex-wrap p-6 pt-2">
            <WppTypography type="l-strong">Team Members</WppTypography>
            <WppSkeleton width="100%" height={80} />
            <WppSkeleton width="100%" height={80} />
            <WppSkeleton width="100%" height={80} />
          </div>
        )}
        {!isCreator && !fetching && (
          <div className="flex gap-4 flex-wrap p-6 pt-2">
            <WppTypography type="l-strong">Team Members</WppTypography>
            <ProjectMembersGrid members={members || []} />
          </div>
        )}
      </div>
    </div>
  )
}
