import { useEffect, useState } from 'react'

import { Agency } from 'types/agencies/agencies'

import { sortType } from './AgencyFilterGroup/types'

export type AgencySortFilterReturn = [
  { data: Agency[]; activeSortType: sortType; sortAsc: boolean },
  (agencies: Agency[], type: sortType) => Agency[],
]

export const useAgencySortFilters = (type: sortType = 'name', agencies?: Agency[]): AgencySortFilterReturn => {
  const [sortAsc, setSortAsc] = useState<boolean>(true)
  const [activeSortType, setActiveSortType] = useState<sortType>(type)
  const [sortedData, setSortedData] = useState<Agency[]>([])

  const handleSortName = (agencies: Agency[]) => {
    const sortedData = agencies.toSorted((a, b) => {
      const operator = !sortAsc ? -1 : 1
      return operator * a.name.localeCompare(b.name)
    })
    setSortedData(sortedData)
    setSortAsc(!sortAsc)
    setActiveSortType('name')
    return sortedData
  }
  const handleSortCategories = (agencies: Agency[]) => {
    let sortedData = agencies.toSorted((a, b) => a.numberOfCategories - b.numberOfCategories)
    if (sortAsc) {
      sortedData = sortedData.reverse()
    }
    setSortedData(sortedData)
    setSortAsc(!sortAsc)
    setActiveSortType('categories')
    return sortedData
  }
  const handleSortCreatedBy = (agencies: Agency[]) => {
    const sortedData = agencies.toSorted((a, b) => {
      const operator = !sortAsc ? -1 : 1
      return operator * a.createdBy?.name.localeCompare(b.createdBy?.name)
    })
    setSortedData(sortedData)
    setSortAsc(!sortAsc)
    setActiveSortType('createdBy')
    return sortedData
  }
  const handleSortDateFromTo = (agencies: Agency[]) => {
    const sortedData = agencies.toSorted((a, b) => {
      const operator = !sortAsc ? -1 : 1
      return operator * b.createdAt.localeCompare(a.createdAt)
    })
    setSortedData(sortedData)
    setSortAsc(!sortAsc)
    setActiveSortType('dateFromTo')
    return sortedData
  }

  const handleSortFilter = (agencies: Agency[], type: sortType) => {
    switch (type) {
      case 'name': {
        return handleSortName(agencies)
      }
      case 'categories': {
        return handleSortCategories(agencies)
      }
      case 'createdBy': {
        return handleSortCreatedBy(agencies)
      }
      case 'dateFromTo': {
        return handleSortDateFromTo(agencies)
      }
      default: {
        return []
      }
    }
  }

  useEffect(() => {
    // sort on initial render
    if (type && agencies) {
      handleSortFilter(agencies, type)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // return the handlers and the active sort type
  return [{ data: sortedData, activeSortType, sortAsc }, handleSortFilter]
}
