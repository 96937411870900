/* eslint-disable react-hooks/exhaustive-deps */
import { MentionData } from '@draft-js-plugins/mention'
import {
  WppActionButton,
  WppIconChatMessage,
  WppIconClose,
  WppIconCopy,
  WppIconDone,
  WppIconEdit,
  WppIconMore,
  WppIconTrash,
  WppIconUserTag,
  WppListItem,
  WppMenuContext,
  WppSpinner,
  WppTextareaInput,
  WppTypography,
  WppTooltip,
} from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import clsx from 'clsx'
import { format } from 'date-fns'
import { useEffect, useMemo, useRef, useState } from 'react'
import Markdown from 'react-markdown'

import { useEditComment } from 'api/mutations/rfis/comments/useEditComment'
import { usePostComment } from 'api/mutations/rfis/comments/usePostComment'
import { usePatchRfiQuestionId } from 'api/mutations/rfis/usePatchRfiQuestionId'
import { getRfiQuestionById } from 'api/queries/rfis/useRfiQuestionId'
import { useTasksStatus } from 'api/queries/task-status/useTasksStatus'
import stylesMain from 'assets/main.module.scss'
import { LoaderProgressWithDescription } from 'components/LoaderProgressWithDescription'
import { useToast } from 'hooks/useToast'
import { RfiComment, RfiMember } from 'types/rfis/rfi'
import { Role } from 'types/users/userList'

import styles from './ConversationMessage.module.scss'
import { Message, CommentState } from '../ChatCmp'
import { CommentInput } from '../comment-input/CommentInput'
import { MessageType } from '../types'

export type CommentAction = 'edit' | 'delete' | 'add'
const { Commenter, Contributor, Viewer } = Role

interface Props {
  message: Message
  isTaskRunning?: boolean
  updateConversation: React.Dispatch<React.SetStateAction<Message[]>>
  commentState: CommentState
  projectMembers: RfiMember[]
  setCommentState: React.Dispatch<React.SetStateAction<CommentState>>
  handleUpdateCommentList: ({
    messageId,
    commentData,
  }: {
    messageId: string
    commentData: RfiComment
    action: CommentAction
  }) => void
  handleDeleteComment: (commentId: string) => void
}

export const ConversationMessage = ({
  message,
  commentState,
  setCommentState,
  handleDeleteComment,
  projectMembers,
  isTaskRunning,
  handleUpdateCommentList,
}: Props) => {
  const {
    osContext: {
      userDetails: { email: currentUserEmail },
    },
  } = useOs()
  const [, trigger] = getRfiQuestionById()
  const { showToast } = useToast()
  const [updating, setUpdating] = useState<string | null>(null)
  const rfiQuestionId = message?.rfiQuestionId
  const parentQuestionId = message?.parentId

  const projectMember = projectMembers.filter(member => member.memberDetail.email === currentUserEmail)
  const isProjectOwner = projectMember?.[0]?.role === Role.Owner

  const scrollDivRef = useRef<HTMLDivElement>(null)

  const members: MentionData[] = useMemo(() => {
    return projectMembers.map(user => ({
      ...user,
      name: user.memberDetail.name,
      avatar: user.memberDetail.img,
      email: user.memberDetail.email,
    }))
  }, [projectMembers])

  const { mutateAsync: editProject, isPending } = usePatchRfiQuestionId()
  const { data: taskStatus } = useTasksStatus({
    params: { taskId: updating || '' },
    enabled: !!updating,
    refetchInterval: 2000,
  })

  const { mutateAsync: editComment, isPending: isEditingComment } = useEditComment({
    onSuccess: data => {
      setCommentState(prev => ({
        ...prev,
        isEdit: false,
        showComments: true,
      }))
      handleUpdateCommentList({ messageId: message.id, commentData: data.data, action: 'edit' })
      scrollToView()
    },
    onError: e => {
      const err = e.response?.data as any
      showToast({
        type: 'error',
        message: err.response?.data?.message ?? 'An error occurred while editing comment',
      })
    },
  })

  const { mutateAsync: postComment, isPending: isPostingComment } = usePostComment({
    onSuccess: data => {
      handleUpdateCommentList({ messageId: message.id, commentData: data.data, action: 'add' })
      scrollToView()
    },
    onError: e => {
      const err = e.response?.data as any
      showToast({
        type: 'error',
        message: err.response?.data?.message ?? 'An error occurred while adding comment',
      })
    },
  })

  const [isEditQuestionOpen, setIsEditQuestionOpen] = useState(false)
  const [content, setContent] = useState('')

  const copyToClipboard = () => {
    navigator.clipboard.writeText(message.content)
    showToast({
      message: 'Message response copied to clipboard',
      type: 'success',
      duration: 4000,
    })
  }

  const handleChange = (event: any) => {
    setContent(event.target.value)
  }

  const handleCloseEdit = () => {
    setContent(message.content)
    setIsEditQuestionOpen(false)
  }

  const handleSubmit = async (question_id: string = rfiQuestionId || '', proposedAnswer: string = content) => {
    try {
      const progress = await editProject({ question_id, proposedAnswer })
      setIsEditQuestionOpen(false)
      setUpdating(progress.data.id)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handlePostComment = async (comment: string) => {
    try {
      await postComment({
        rfiQuestionId: rfiQuestionId!,
        commentText: comment,
        isQuestionComment: message.type === MessageType.QUESTION,
      })
    } catch (e) {
      showToast({
        message: (e as any).toString(),
        type: 'error',
        duration: 4000,
      })
    }
  }

  const handleEditComment = async (comment: string, commentId: string) => {
    try {
      await editComment({
        commentId,
        rfiQuestionId: rfiQuestionId!,
        commentText: comment,
        isQuestionComment: message.type === MessageType.QUESTION,
      })
    } catch (e) {
      showToast({
        message: (e as any).toString(),
        type: 'error',
        duration: 4000,
      })
    }
  }

  const scrollToView = () => {
    const scrollImmediateTimeout = setTimeout(() => {
      scrollDivRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    }, 10)

    return () => {
      clearTimeout(scrollImmediateTimeout)
    }
  }

  useEffect(() => {
    if (taskStatus?.completed) {
      setUpdating(null)
      const question_id = parentQuestionId || rfiQuestionId || ''
      trigger({ question_id })
      return
    }
  }, [taskStatus])

  useEffect(() => {
    setContent(message.content)
  }, [message.content])

  const hasRequiredRole = (requiredRoles: Role[]) => {
    if (!projectMember) {
      return false
    }
    return isProjectOwner || requiredRoles.includes(projectMember[0]?.role as Role)
  }

  const isCommentOwner = (email: string) => {
    return email === currentUserEmail
  }

  const handleToggleComments = (messageId: string) => {
    if (commentState.showComments) {
      return setCommentState(prev => ({
        ...prev,
        showComments: false,
        openInput: false,
      }))
    }
    setCommentState(prev => ({
      ...prev,
      showComments: true,
      openInput: true,
      messageId,
    }))
    scrollToView()
  }

  const hasComments = message.comments?.length > 0 && !isEditQuestionOpen && !updating
  const showComments = hasComments && commentState.showComments && commentState.messageId === message.id
  const showCommentInput =
    !updating && !commentState.isEdit && commentState.openInput && commentState.messageId === message.id

  return (
    <div className="relative gap-4" data-qa-container>
      {!isEditQuestionOpen && !updating && (
        <div className={clsx(message.type === MessageType.QUESTION ? 'flex justify-end' : 'flex justify-start')}>
          <div
            className={clsx(
              message.type === MessageType.QUESTION ? ' bg-[#E0EBFF] rounded-8 p-3' : 'flex justify-start ',
            )}
          >
            <Markdown
              className={clsx(
                message.type === MessageType.QUESTION && stylesMain.blackPMobile,
                message.type === MessageType.ANSWER && stylesMain.captionregular,
                'whitespace-pre-wrap',
              )}
            >
              {message.content}
            </Markdown>
          </div>
        </div>
      )}

      {hasRequiredRole([Viewer, Commenter, Contributor]) && hasComments && (
        <div className="justify-start">
          <button
            className="text-sm text-[#0014CC] mt-1"
            onClick={() => {
              handleToggleComments(message.id)
            }}
          >
            <span>
              {message.comments.length} Comment{message.comments.length > 1 && 's'}
            </span>
          </button>
        </div>
      )}

      {!updating && !isTaskRunning && message.content && (
        <div
          className={clsx(
            'sticky bottom-2 right-2 -mt-4 ml-auto bg-white shadow-md p-1 rounded-8 h-[38px] flex flex-row justify-between px-[3px]',
            hasRequiredRole([Commenter, Contributor]) ? 'max-w-[126px]' : 'max-w-[80px]',
          )}
          data-qa-editable
        >
          {hasRequiredRole([Commenter, Contributor]) && (
            <WppTooltip text="Comments">
              <WppActionButton
                onClick={() => {
                  handleToggleComments(message.id)
                }}
              >
                <WppIconChatMessage />
              </WppActionButton>
            </WppTooltip>
          )}
          <WppTooltip text="Copy to clipboard">
            <WppActionButton onClick={copyToClipboard} disabled={isPending}>
              <WppIconCopy size="s" />
            </WppActionButton>
          </WppTooltip>
          {isPending ? (
            <WppSpinner className="cursor-not-allowed" />
          ) : (
            <WppTooltip text="Edit">
              <WppActionButton onClick={() => setIsEditQuestionOpen(true)}>
                <WppIconEdit size="s" />
              </WppActionButton>
            </WppTooltip>
          )}
        </div>
      )}

      {hasRequiredRole([Viewer, Commenter, Contributor]) && showComments && (
        <div className="flex gap-2 flex-col mt-2 items-end">
          {message.comments.map(item => (
            <div key={item.id} className="p-3 bg-[#F8F9FB] w-[90%]">
              <div className="flex justify-between">
                <WppTypography type="xs-midi">{item.createdBy.name}</WppTypography>
                <WppTypography type="xs-midi">
                  {format(item.updatedAt ?? item.createdAt, 'MM/dd/yyyy kk:mm')}{' '}
                  {item.updatedAt && <span className="text-[#827b7b] text-xs">(edited)</span>}
                </WppTypography>
              </div>

              <>
                {commentState.isEdit && commentState.selectedId === item.id ? (
                  hasRequiredRole([Commenter, Contributor]) && (
                    <div className="w-[90%]">
                      <CommentInput
                        handleSubmit={(commentText, action) =>
                          action === 'edit' ? handleEditComment(commentText, item.id) : handlePostComment(commentText)
                        }
                        isReplyLoading={isEditingComment}
                        initialComment={item.commentText}
                        projectMembers={members}
                        isEdit={commentState.isEdit}
                        handleCloseEdit={() => setCommentState(prev => ({ ...prev, isEdit: false }))}
                      />
                    </div>
                  )
                ) : (
                  <div className="flex justify-between items-end gap-5">
                    <WppTypography type="s-body">{item.commentText}</WppTypography>
                    <WppMenuContext dropdownConfig={{ placement: 'bottom-end' }} className="w-auto">
                      {((isCommentOwner(item.createdBy.email) && hasRequiredRole([Commenter, Contributor])) ||
                        isProjectOwner) && (
                        <WppIconMore
                          slot="trigger-element"
                          direction="horizontal"
                          className="cursor-pointer mt-2 w-auto"
                        />
                      )}
                      {isCommentOwner(item.createdBy.email) && (
                        <WppListItem
                          onWppChangeListItem={() =>
                            setCommentState(prev => ({ ...prev, isEdit: true, selectedId: item.id }))
                          }
                        >
                          <WppIconUserTag slot="left" />
                          <p slot="label">Edit</p>
                        </WppListItem>
                      )}
                      {(isCommentOwner(item.createdBy.email) || isProjectOwner) && (
                        <WppListItem onWppChangeListItem={() => handleDeleteComment(item.id)}>
                          <WppIconTrash slot="left" />
                          <p slot="label">Delete Comment</p>
                        </WppListItem>
                      )}
                    </WppMenuContext>
                  </div>
                )}
              </>
            </div>
          ))}
          <div ref={scrollDivRef} />
        </div>
      )}

      {/* show comment editor */}
      {hasRequiredRole([Commenter, Contributor]) && showCommentInput && (
        <div className="mt-2 flex justify-end">
          <div className="w-[90%]">
            <CommentInput
              handleSubmit={commentText => handlePostComment(commentText)}
              isReplyLoading={isPostingComment}
              projectMembers={members}
              handleCloseEdit={() => setCommentState(prev => ({ ...prev, isEdit: false }))}
            />
          </div>
          <div ref={scrollDivRef} />
        </div>
      )}

      {!!updating && (
        <div className="flex justify-center gap-4 mt-2">
          <LoaderProgressWithDescription taskStatus={taskStatus} />
        </div>
      )}

      {isEditQuestionOpen && (
        <>
          <WppTextareaInput
            name="edit-content"
            placeholder="Ask a question to explore more options..."
            value={content}
            className={clsx(styles.textarea, message.type === MessageType.QUESTION ? styles.question : styles.answer)}
            onWppChange={handleChange}
          />
          <div className="flex justify-end gap-4 mt-2">
            <div className="cursor-pointer" onClick={handleCloseEdit}>
              <WppIconClose />
            </div>
            {isPending ? (
              <WppSpinner />
            ) : (
              <div className="cursor-pointer" onClick={() => handleSubmit(rfiQuestionId, content)}>
                <WppIconDone />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}
