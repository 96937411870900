import { debounce } from '@wppopen/components-library'
import type { WppAutocompleteCustomEvent } from '@wppopen/components-library/dist/types/components'
import {
  WppAutocomplete,
  WppButton,
  WppModal,
  WppAvatar,
  WppListItem,
  WppTypography,
} from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'

import { useSearchUsers } from 'api/queries/users/useSearchUsers'
import { SelectedValues, type SelectedAutoCompleteOption } from 'components/users/SelectedValues'
import { UsersList } from 'components/users/UsersList'
import { CreatedOrUpdatedBy } from 'types/rfis/rfi'
import { GetRfiReturn, Role, type IUser, type IUserRole } from 'types/users/userList'

import style from './UsersList.module.scss'

export type OnUsersupdated = (updatedUsers: GetRfiReturn[], prevUsers: GetRfiReturn[]) => void

export const UserCollaboration = ({
  disabledInputsCondition,
  isTaskRunning,
  initUsers = [],
  snug = false,
  onUsersUpdated,
  creator,
}: {
  disabledInputsCondition: boolean
  initUsers?: IUser[]
  isTaskRunning: boolean
  snug?: boolean
  creator?: CreatedOrUpdatedBy
  /*
   *callback handler for when updatedUsers are updated
   */
  onUsersUpdated: OnUsersupdated
}) => {
  const {
    osContext: { userDetails },
  } = useOs()

  const [usersSelected, setUsersSelected] = useState<SelectedAutoCompleteOption[]>([])

  const [userToRemove, setUserToRemove] = useState<SelectedAutoCompleteOption | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [usersAdded, setUsersAdded] = useState<SelectedAutoCompleteOption[]>([])
  const prevUsersAdded = useRef<SelectedAutoCompleteOption[]>(usersAdded)

  const [{ data: usersResult, isLoading, isFetching }, userSearchTrigger] = useSearchUsers()
  const handleDebounce = debounce(
    (e: WppAutocompleteCustomEvent<string>) =>
      userSearchTrigger({
        username: e.detail,
      }),
    333,
  )
  const onUserSearch = handleDebounce

  /* methods */
  const toggleModal = () => setIsModalOpen(prev => !prev)
  const getRfiMembers = (users: IUser[]): GetRfiReturn[] =>
    users.map(user => ({ member: user.email, role: user.role?.name || Role.Contributor }))

  const isUserSelected = (user: IUser) => {
    return usersAdded.some(usr => usr.id === user.id) || user.id === userDetails.id
  }

  const onRoleChange = ({ userId, role }: { userId: string; role: IUserRole }) => {
    const updatedUsers = usersAdded.map(user => {
      if (user.id === userId) {
        return { ...user, role }
      }
      return user
    })
    setUsersAdded(updatedUsers)
    onUsersUpdated(getRfiMembers(updatedUsers), getRfiMembers(prevUsersAdded.current))
    prevUsersAdded.current = updatedUsers
  }

  const onAutoCompleteClickOutside = (e: Event) => {
    const role = (e.target as EventTarget & { getAttribute: (x: string) => string }).getAttribute('role')

    if (!['WPP-LIST-ITEM', 'checkbox'].includes(role)) {
      const updatedUsers = [...usersAdded, ...usersSelected]
      setUsersAdded(updatedUsers)
      onUsersUpdated(getRfiMembers(updatedUsers), getRfiMembers(prevUsersAdded.current))
      prevUsersAdded.current = updatedUsers
      setTimeout(() => {
        setUsersSelected([])
      }, 200)
    }
  }

  useEffect(() => {
    if (initUsers && initUsers.length > 0) {
      /* add a label property */
      const mappedInitUsers = initUsers.map(user => ({
        ...user,
        label: `${user.firstname} ${user.lastname}`,
      }))
      setUsersAdded(mappedInitUsers)
      prevUsersAdded.current = mappedInitUsers
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initUsers])

  return (
    <>
      <WppTypography type="xl-heading">Team members</WppTypography>
      {!snug && (
        <WppTypography type="m-body" className={style.subText}>
          Invite Users to Collaborate on this project
        </WppTypography>
      )}{' '}
      <div className={clsx('relative', style.item, !snug && 'mt-7 mb-10')}>
        <WppAutocomplete
          required
          name="users"
          placeholder="Select member(s)"
          disabled={disabledInputsCondition || undefined}
          value={usersSelected}
          onWppChange={e => setUsersSelected(e.detail.value as SelectedAutoCompleteOption[])}
          onWppSearchValueChange={onUserSearch}
          dropdownConfig={{
            onClickOutside: (_, e) => onAutoCompleteClickOutside(e),
          }}
          multiple
          loading={isLoading || isFetching}
        >
          {usersResult?.data
            ?.map(u => ({ ...u, label: `${u.firstname} ${u.lastname}` }))
            .map(user => (
              <WppListItem key={user.id} value={user} label={user.label} disabled={isUserSelected(user)}>
                <p slot="label">{user.label}</p>
                <p slot="caption">{user.email}</p>
                <WppAvatar
                  size="xs"
                  src={user?.avatarUrl || ''}
                  name={`${user?.firstname + user?.lastname || ''}`}
                  slot="left"
                />
              </WppListItem>
            ))}
          <SelectedValues
            values={usersSelected}
            onCloseClick={value => {
              setUsersSelected(usersSelected.filter(i => i.id !== value))
            }}
          />
        </WppAutocomplete>
      </div>
      <UsersList
        userDetails={userDetails}
        users={usersAdded}
        onRoleChange={onRoleChange}
        disabled={isTaskRunning}
        onRemoveUser={user => {
          setUserToRemove(user as SelectedAutoCompleteOption)
          toggleModal()
        }}
        creator={creator}
      />
      <WppModal open={isModalOpen}>
        <h3 slot="header">Remove Member?</h3>
        <p slot="body">
          Are you sure you want to remove <strong>{`${userToRemove?.firstname} ${userToRemove?.lastname}`}</strong>?
        </p>
        <div slot="actions" className="flex gap-2 justify-end">
          <WppButton variant="secondary" size="s" onClick={toggleModal}>
            Cancel
          </WppButton>
          <WppButton
            variant="destructive"
            size="s"
            onClick={() => {
              toggleModal()
              /*
               * can this be moved to a useEffect?
               * so that user updates are stored in state locally
               * the useEffect then triggers callback with the updated user list
               * */
              const updatedUsers = usersAdded.filter(u => u.id !== userToRemove?.id)
              setUsersAdded(updatedUsers)
              onUsersUpdated(getRfiMembers(updatedUsers), getRfiMembers(prevUsersAdded.current))
              prevUsersAdded.current = updatedUsers
            }}
          >
            Remove
          </WppButton>
        </div>
      </WppModal>
    </>
  )
}
