// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QorFX::part(textarea){color:var(--color-font-grey);font-weight:500;font-size:14px;font-family:TCCC-UnityHeadline,sans-serif;font-style:normal}.GhivK::part(textarea){height:10dvh}.NNuXB::part(textarea){height:40dvh}[data-qa-container] [data-qa-editable]{opacity:0;transition:opacity .33s ease}[data-qa-container]:hover [data-qa-editable]{opacity:1;transition:opacity .33s ease}`, "",{"version":3,"sources":["webpack://./src/components/chat/conversation-message/ConversationMessage.module.scss"],"names":[],"mappings":"AACE,uBACE,4BAAA,CACA,eAAA,CACA,cAAA,CACA,yCAAA,CACA,iBAAA,CAKF,uBACE,YAAA,CAKF,uBACE,YAAA,CAGJ,uCACE,SAAA,CACA,4BAAA,CAGF,6CACE,SAAA,CACC,4BAAA","sourcesContent":[".textarea {\n  &::part(textarea) {\n    color: var(--color-font-grey);\n    font-weight: 500;\n    font-size: 14px;\n    font-family: TCCC-UnityHeadline, sans-serif;\n    font-style: normal;\n  }\n}\n\n.question {\n  &::part(textarea) {\n    height: 10dvh;\n  }\n}\n\n.answer {\n  &::part(textarea) {\n    height: 40dvh;\n  }\n}\n[data-qa-container] [data-qa-editable]{\n  opacity: 0;\n  transition: opacity 0.33s ease;\n}\n\n[data-qa-container]:hover [data-qa-editable]{\n  opacity: 1;\n   transition: opacity 0.33s ease;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": `QorFX`,
	"question": `GhivK`,
	"answer": `NNuXB`
};
export default ___CSS_LOADER_EXPORT___;
