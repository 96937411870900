import {
  WppTextareaInput,
  WppButton,
  WppDivider,
  WppFileUpload,
  WppInput,
  WppTypography,
} from '@wppopen/components-library-react'
import { useCallback, useEffect, useRef, useState } from 'react'

import { AddAgencyParams } from 'api/fetchers/agencies/addAgency'
import { useAddAgency } from 'api/mutations/agencies/useAddAgency'
import { useUpdateAgency } from 'api/mutations/agencies/useUpdateAgency'
import { useTasksStatus } from 'api/queries/task-status/useTasksStatus'
import { queryClient } from 'app/Root'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { Agency, AgencyAddedResponse } from 'types/agencies/agencies'

import style from './editaddagency.module.scss'

export interface EditAddAgencyProps {
  agency?: Agency
  handleCancel?: () => void
  handleSave?: (name?: string, description?: string) => void
}

export const EditAddAgency = ({ agency, handleCancel, handleSave }: EditAddAgencyProps) => {
  const [descriptionValue, setDescriptionValue] = useState<string>(agency?.description || '')
  const [nameValue, setNameValue] = useState<string>(agency?.name || '')
  const [wppFiles, setWppFiles] = useState<File[] | null>(null)
  const textAreaRef = useRef<HTMLWppTextareaInputElement>(null)
  const [disableSave, setDisableSave] = useState(!agency?.name)
  const [task, setTask] = useState<AgencyAddedResponse | null>(null)
  const toast = useToast()
  const { data: taskStatus } = useTasksStatus({
    params: { taskId: task?.id || '' },
    enabled: !!task?.id,
    refetchInterval: wppFiles?.length ? 5000 : 2000,
  })
  const isEditMode = agency ? 'edit' : 'add'
  const agencyId = agency?.id || ''

  const maxLength = 2000
  const { mutateAsync: addAgency } = useAddAgency({
    onError: error => {
      toast.showToast({
        message: error.message,
        type: 'error',
      })
    },
  })
  const { mutateAsync: updateAgency } = useUpdateAgency()

  const prepareRequest = useCallback((): AddAgencyParams => {
    let request: AddAgencyParams = { params: { name: nameValue, description: descriptionValue } }
    if (wppFiles) {
      const formData = new FormData()
      /* generate the files blob */
      const files = wppFiles.map(file => new Blob([file], { type: 'application/pdf' }))
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        formData.append('files', file, wppFiles[i]?.name)
      }
      request = { formData, params: { name: nameValue, description: descriptionValue } }
    }
    if (agencyId) {
      request.agencyId = agencyId
    }
    /*  */
    return request
  }, [descriptionValue, nameValue, wppFiles, agencyId])

  const handleFileUploadChange = (event: CustomEvent) => setWppFiles(event.detail.value)
  const handleClearAll = () => {
    setNameValue('')
    setDescriptionValue('')
    setWppFiles(null)
  }
  /*
   * For updating in the background we doing it on blur
   */
  const handleBlur = async () => {
    if (isEditMode && typeof agency?.id !== 'undefined') {
      if (!nameValue) {
        return toast.showToast({
          message: 'Please enter a name for the agency',
          type: 'error',
        })
      }
      const request = prepareRequest()

      try {
        const res = await updateAgency(request)
        if (wppFiles) {
          toast.showToast({
            message: 'Parsing files for processing...',
            type: 'information',
            duration: 5000,
          })
        }
        setTask(res.data)
        handleSave && handleSave(nameValue, descriptionValue)
      } catch (e) {
        toast.showToast({
          message: 'Something went wrong while adding the agency. Please try again.',
          type: 'error',
        })
      }
    }
  }
  /*
   * error & success handling
   */
  const onHandleSave = useCallback(async () => {
    setDisableSave(true)
    let request = prepareRequest()

    if (!nameValue) {
      return toast.showToast({
        message: 'Please enter a name for the agency',
        type: 'error',
      })
    }

    try {
      const res = await addAgency(request)
      if (wppFiles) {
        toast.showToast({
          message: 'Parsing files for processing...',
          type: 'information',
          duration: 5000,
        })
      }
      setTask(res.data)
      handleSave && handleSave(nameValue, descriptionValue)
    } catch (e) {
      toast.showToast({
        message: 'Something went wrong while adding the agency. Please try again.',
        type: 'error',
      })
    }
    setDisableSave(false)
    handleClearAll()
  }, [addAgency, descriptionValue, handleSave, nameValue, prepareRequest, toast, wppFiles])

  const onHandleCancel = () => {
    handleClearAll()
    handleCancel && handleCancel()
  }

  const handleCheckCharsOnPaste = (e: React.ClipboardEvent) => {
    const { clipboardData } = e
    const text = clipboardData.getData('text')
    if (text.length + descriptionValue.length > maxLength) {
      e.preventDefault()
      toast.showToast({
        message: `You can't paste more than ${maxLength} characters`,
        type: 'error',
      })
    }
  }

  useEffect(() => {
    if (textAreaRef.current) {
      const shadowRoot = textAreaRef.current.shadowRoot
      const textArea = shadowRoot?.querySelector('textarea')
      textArea?.setAttribute('maxlength', maxLength.toString())
    }
  }, [descriptionValue])

  useEffect(() => {
    const { status } = taskStatus || {}
    if (status === 'Parsing files for processing...') {
      toast.showToast({
        message: 'Parsing files for processing...',
        type: 'information',
        duration: 14000,
      })
    }
    if (status === 'failed') {
      toast.showToast({
        message: 'Something went wrong while adding the agency. Please try again.',
        type: 'error',
      })
    }
    if (status?.includes('Error')) {
      toast.showToast({
        message: status,
        type: 'error',
      })
    }

    if (status === 'completed') {
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.AGENCIES] }).then(() => {
        toast.showToast({
          message: `Agency  ${agency ? 'updated' : 'added'} successfully`,
          type: 'success',
        })
      })
      setTask(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agency, taskStatus])

  useEffect(() => {
    setDisableSave(!nameValue)
  }, [nameValue])

  useEffect(() => {
    if (isEditMode) {
      // setWppFiles(null)
    }
  }, [isEditMode])

  return (
    <>
      <div className="py-4">
        <WppInput
          name={nameValue}
          placeholder="Add an agency name"
          required
          labelConfig={{
            text: 'Agency Name',
          }}
          value={nameValue}
          onWppBlur={handleBlur}
          onWppChange={e => setNameValue(e?.detail?.value || '')}
        />
      </div>
      <div className="py-4">
        <WppTextareaInput
          ref={textAreaRef}
          onWppBlur={handleBlur}
          labelConfig={{
            text: 'Description',
          }}
          onWppChange={e => setDescriptionValue(e?.detail?.value || '')}
          value={descriptionValue}
          charactersLimit={maxLength}
          maxMessageLength={maxLength}
          warningThreshold={maxLength - 100}
          content={descriptionValue}
          // onPaste={handleCheckCharsOnPaste}
          onPaste={handleCheckCharsOnPaste}
          className={style.textArea}
        />
      </div>
      <div className="py-4">
        <div className="mb-2">
          <WppTypography type="s-strong" className={style.fileUploadtype}>
            Knowledge Base Upload
          </WppTypography>
          <WppTypography type="s-body" className="ml-1">
            (Optional)
          </WppTypography>
        </div>
        <WppFileUpload
          name="description"
          acceptConfig={{ 'application/pdf': ['.pdf'] }}
          onWppChange={handleFileUploadChange}
          format="arrayBuffer"
          onWppBlur={handleBlur}
        />
      </div>
      {!agency && (
        <>
          <WppDivider />
          <div className="flex ">
            <WppButton onClick={onHandleCancel} className="ml-auto mr-4" variant="secondary">
              Cancel
            </WppButton>
            <WppButton
              onClick={() => {
                onHandleSave()
              }}
              className="mr-4"
              disabled={disableSave}
            >
              Save
            </WppButton>
          </div>
        </>
      )}
    </>
  )
}
