import { createLazyQuery } from 'api/common/createUseQuery'
import { userSearch } from 'api/fetchers/users/userSearch'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { IUser } from 'types/users/userList'

export const useSearchUsers = createLazyQuery({
  queryKey: ApiQueryKeys.USER_SEARCH,
  fetcher: userSearch,
  selector: res => res?.data ?? ({ data: [] } as { data: IUser[] }),
})
