// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HBMEg::part(item){padding-left:0;width:100%;cursor:pointer;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.HBMEg::part(item):hover{background-color:rgba(0,0,0,0)}`, "",{"version":3,"sources":["webpack://./src/pages/home/home.module.scss"],"names":[],"mappings":"AAAA,mBACI,cAAA,CACA,UAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CACA,sBAAA,CACA,yBACI,8BAAA","sourcesContent":[".listItem::part(item){\n    padding-left: 0;\n    width: 100%;\n    cursor:pointer;\n    overflow: hidden; /* or clip */\n    white-space: nowrap; /* or pre */\n    text-overflow: ellipsis;\n    &:hover{\n        background-color: transparent;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listItem": `HBMEg`
};
export default ___CSS_LOADER_EXPORT___;
