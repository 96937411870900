import {
  WppActionButton,
  WppAvatar,
  WppDivider,
  WppIconMore,
  WppListItem,
  WppMenuContext,
  WppTooltip,
  WppTypography,
} from '@wppopen/components-library-react'
import clsx from 'clsx'
import { Fragment, ReactNode, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Link, LinkProps } from 'react-router-dom'

import { Agency } from 'types/agencies/agencies'

import style from './agencyDashboard.module.scss'

export default function AgencyCard({
  name,
  id,
  createdAt,
  createdBy,
  numberOfCategories,
  handleEditAgencyClick,
  handleDeleteAgencyClick,
}: Agency & {
  handleEditAgencyClick: (id: string) => void
  handleDeleteAgencyClick: (id: string, name: string) => void
}) {
  const titleRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<LinkProps | null>(null)
  const [truncate, setTruncate] = useState(false)
  const [containerWidth, setContainerWidth] = useState(0)
  const rightSidePadding = 40

  /* format date */
  const createdAtDate = new Intl.DateTimeFormat('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(new Date(createdAt))

  const handleTruncate = useCallback(() => {
    // setTruncate(true)
    setContainerWidth((containerRef.current as HTMLElement | null)?.clientWidth || 0)

    // get the width of the titleRef.current
    const titleWidth = (titleRef.current as HTMLElement | null)?.clientWidth || 0
    // get the width of the containerRef.current
    const containerWidth = (containerRef.current as HTMLElement | null)?.clientWidth || 0

    if (titleWidth > containerWidth - rightSidePadding) {
      setTruncate(true)
    }
  }, [])

  const handleContextClick = (e: SyntheticEvent) => {
    /* prevent bubbling up to main click event */
    e.preventDefault()
    e.stopPropagation()
  }

  useEffect(() => {
    if (titleRef?.current && containerRef?.current) {
      handleTruncate()
    }
    window.addEventListener('resize', handleTruncate)
    return () => {
      window.removeEventListener('resize', handleTruncate)
    }
  }, [containerWidth, handleTruncate])

  const Component = truncate
    ? ({ children }: { children: ReactNode }) => <WppTooltip text={name}>{children}</WppTooltip>
    : Fragment
  return (
    <Link
      ref={containerRef as React.RefObject<HTMLAnchorElement>}
      id={id.toString()}
      className={clsx(
        'flex hover:bg-[#e9eaeb] hover:shadow transition-all duration-200 ease-in-out relative flex-col rounded-8 overflow-hidden',
        style.agencyCardContainer,
      )}
      title={name}
      to={`/rfi-helper-tool/agency-dashboard/${id}`}
    >
      <div className="flex flex-row justify-between">
        <div ref={titleRef}>
          <Component>
            <WppTypography
              type="l-strong"
              className={clsx('whitespace-nowrap truncate', style.agencyCardTitle)}
              tag="p"
              style={{ width: `${containerWidth - rightSidePadding}px` }}
            >
              {name}
            </WppTypography>
          </Component>
        </div>

        <WppMenuContext className="w-1/5" onClick={handleContextClick}>
          <WppActionButton className="absolute top-2 right-2" slot="trigger-element">
            <WppIconMore direction="horizontal" />
          </WppActionButton>
          <div>
            <WppListItem onWppChangeListItem={() => handleEditAgencyClick(id)}>
              <p slot="label">Edit</p>
            </WppListItem>
            <WppListItem onWppChangeListItem={() => handleDeleteAgencyClick(id, name)}>
              <p slot="label">Delete</p>
            </WppListItem>
          </div>
        </WppMenuContext>
      </div>

      <div className="flex">
        <WppTypography className={style.agencyCardText} type="s-body">
          Knowledge Base: {numberOfCategories} categories
        </WppTypography>
      </div>
      <div className="flex">
        <WppTypography className={style.agencyCardText} type="s-body">
          {createdAtDate}
        </WppTypography>
      </div>
      <WppDivider />
      <div className="flex flex-row justify-start">
        <WppAvatar withTooltip name={createdBy?.name} src={createdBy?.img || ''} />
      </div>
    </Link>
  )
}
