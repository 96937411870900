import { InputChangeEventDetail } from '@wppopen/components-library'
import { WppInputCustomEvent } from '@wppopen/components-library/dist/types/components'
import {
  WppActionButton,
  WppButton,
  WppFilterButton,
  WppIconAdd,
  WppIconButton,
  WppIconDataViewCards,
  WppIconDataViewList,
  WppIconReset,
  WppInput,
  WppListItem,
  WppMenuContext,
  WppSideModal,
  WppSortButton,
} from '@wppopen/components-library-react'
import clsx from 'clsx'
import { useState } from 'react'

import { EditAddAgency } from 'pages/agency-dashboard/EditAddAgency'

import style from './agencyFilterGroup.module.scss'
import { AgencyFilterGroupProps } from './types'

export const AgencyFilterGroup = ({
  handleToggle,
  toggleView,
  handleSearchChange,
  filters = '',
  filterCount = 0,
  handleResetFilters,
  handleApplyFilters,
  handleSortFilter,
  disabled,
  isAsc,
  activeSortType,
  hideSorts = false,
}: AgencyFilterGroupProps) => {
  const onSearchChange = (e: WppInputCustomEvent<InputChangeEventDetail>) => handleSearchChange(e?.detail?.value || '')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState<'filters' | 'add' | ''>('')

  const handleOpenModal = (modal: 'filters' | 'add') => {
    setIsModalOpen(true)
    setModalContent(modal)
  }
  const handleCloseModal = () => setIsModalOpen(false)
  const handleReset = () => {
    handleResetFilters()
  }
  const handleApply = () => {
    handleApplyFilters()
    handleCloseModal()
  }

  return (
    <div className="flex mb-4">
      <div className="flex gap-4 w-1/2">
        <WppInput
          disabled={disabled}
          size="s"
          placeholder="Search"
          aria-label="Search agencies"
          id="agencySearch"
          onWppChange={onSearchChange}
        />
        <WppFilterButton
          name="Filters"
          onClick={() => handleOpenModal('filters')}
          counter={filterCount}
          disabled={disabled}
        >
          Filters
        </WppFilterButton>
        <WppSideModal size="m" open={isModalOpen} onWppSideModalClose={handleCloseModal}>
          {filters && modalContent === 'filters' && (
            <>
              <h3 slot="header">Filter</h3>
              <div slot="body" className="flex flex-col gap-6">
                {filters}
              </div>
              <div slot="actions" className="flex justify-between">
                <WppActionButton variant="primary" onClick={handleReset}>
                  Reset <WppIconReset slot="icon-start" />
                </WppActionButton>
                <WppButton variant="secondary" size="s" onClick={handleCloseModal} className="ml-auto mr-3">
                  Cancel
                </WppButton>
                <WppButton variant="primary" size="s" onClick={handleApply}>
                  Apply
                </WppButton>
              </div>
            </>
          )}
          {modalContent === 'add' && (
            <>
              <h3 slot="header">Add Agency</h3>
              <div slot="body" className="flex flex-col gap-6">
                <EditAddAgency handleSave={handleCloseModal} handleCancel={handleCloseModal} />
              </div>
            </>
          )}
        </WppSideModal>
      </div>

      <div className="flex  ml-auto">
        {!hideSorts && (
          <WppMenuContext className="mr-4">
            <WppSortButton slot="trigger-element" name="Sorts" disabled={disabled}>
              Sort
            </WppSortButton>
            <div>
              <WppListItem onWppChangeListItem={() => handleSortFilter('name')}>
                <p slot="label">Agency Name {activeSortType === 'name' && !isAsc ? '(Z-A)' : '(A-Z)'}</p>
              </WppListItem>
              <WppListItem onWppChangeListItem={() => handleSortFilter('categories')}>
                <p slot="label">Knowledge Base </p>
              </WppListItem>
              <WppListItem onWppChangeListItem={() => handleSortFilter('dateFromTo')}>
                <p slot="label">
                  Date Created
                  {activeSortType === 'dateFromTo' && !isAsc ? ' (Old - New )' : ' (New- Old)'}
                </p>
              </WppListItem>
              <WppListItem onWppChangeListItem={() => handleSortFilter('createdBy')}>
                <p slot="label">Added By {activeSortType === 'createdBy' && !isAsc ? '(Z-A)' : '(A-Z)'}</p>
              </WppListItem>
            </div>
          </WppMenuContext>
        )}

        <WppIconButton
          size="s"
          className={clsx(style.lButton, { [style.selected]: toggleView === 'grid' })}
          onClick={() => handleToggle('grid')}
          disabled={disabled}
        >
          <WppIconDataViewCards />
        </WppIconButton>
        <WppIconButton
          className={clsx(style.rButton, { [style.selected]: toggleView === 'list' })}
          size="s"
          onClick={() => handleToggle('list')}
          disabled={disabled}
        >
          <WppIconDataViewList />
        </WppIconButton>

        <WppButton
          className="ml-4"
          variant="primary"
          onClick={() => handleOpenModal('add')}
          size="s"
          disabled={disabled}
        >
          <WppIconAdd slot="icon-start" />
          New Agency
        </WppButton>
      </div>
    </div>
  )
}
