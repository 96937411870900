import { WppTextareaInput } from '@wppopen/components-library-react'

import styles from './QuestionInput.module.scss'
import InputActions from '../input-actions/InputActions'

interface QuestionInterfaceProps {
  onInput: (value: string) => void
  question: string
  answerIsLoading: boolean
  handleSubmit: () => void
  disabled?: boolean
}

export const QuestionInputNew = ({
  onInput,
  question,
  answerIsLoading,
  handleSubmit,
  disabled,
}: QuestionInterfaceProps) => {
  const handleChange = (event: any) => {
    onInput(event.target.value)
  }
  const handleEnter = (event: any) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <div className={styles.inputContainer}>
      <WppTextareaInput
        name="email"
        disabled={disabled}
        placeholder="Ask a question to explore more options..."
        value={question}
        className={styles.input}
        onWppChange={handleChange}
        onKeyDown={handleEnter}
      />

      <InputActions disabled={disabled} onSubmit={handleSubmit} question={question} answerIsLoading={answerIsLoading} />
    </div>
  )
}
