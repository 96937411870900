import { AutocompleteDefaultOption } from '@wppopen/components-library'
import { WppAvatar, WppPill } from '@wppopen/components-library-react'

import { IUser } from 'types/users/userList'

import styles from './UsersList.module.scss'

export type SelectedAutoCompleteOption = AutocompleteDefaultOption & IUser

interface SelectedValuesComponent {
  values: SelectedAutoCompleteOption[]
  onCloseClick: (a: number | string) => void
}

export const SelectedValues = ({ values, onCloseClick }: SelectedValuesComponent) => (
  <div slot="selected-values">
    {values.map(value => (
      <WppPill
        label={value.label}
        removable
        value={value.id}
        onWppClose={() => onCloseClick(value.id)}
        type="display"
        className={styles.pill}
        key={value.id}
      >
        <WppAvatar size="xs" src={value?.avatarUrl || ''} slot="icon-start" />
        {value.label}
      </WppPill>
    ))}
  </div>
)
