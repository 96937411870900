import { createUseQuery } from 'api/common/createUseQuery'
import { fetchRfiMembers } from 'api/fetchers/users/fetchRfiMembers'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { RfiMember } from 'types/rfis/rfi'

export const useFetchRfiMembers = createUseQuery({
  fetcher: fetchRfiMembers,
  selector: res => res?.data ?? ([] as RfiMember[]),
  queryKey: ApiQueryKeys.RFI_MEMBERS,
})
