// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YYRMj::part(typography){font-size:14px;color:var(--color-font-grey);margin-top:.25rem}.PqmH0::part(button){width:200px;margin-top:.25rem}`, "",{"version":3,"sources":["webpack://./src/components/users/load-more/LoadMore.module.scss"],"names":[],"mappings":"AACE,yBACI,cAAA,CACA,4BAAA,CACA,iBAAA,CAKJ,qBACE,WAAA,CACA,iBAAA","sourcesContent":[".subText {\n  &::part(typography) {\n      font-size: 14px;\n      color: var(--color-font-grey);\n      margin-top: 0.25rem;\n  }\n}\n\n.loadMoreButton {\n  &::part(button) {\n    width: 200px;\n    margin-top: 0.25rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subText": `YYRMj`,
	"loadMoreButton": `PqmH0`
};
export default ___CSS_LOADER_EXPORT___;
